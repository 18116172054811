import React from 'react'
import { Link } from "react-router-dom"
import Stars from "../components/stars"

import checkWebp from "../utils/checkWebp"


const Card = props => {
  let {title, link, rating, img, titleModifier="", webp=img, modifier="", btn} = props
  const hasWebp = checkWebp();
  const bg = hasWebp ? webp : img;

  return (
    <Link to={link}  className={`card ${modifier}`}>
      {bg ? (
        <div className="card__thumbnail img-blue" style={{backgroundImage: `url(${bg})`}}>
        </div>
      ) : (
        <div className="card__thumbnail img-blue" style={{
          backgroundImage: `url(/img/logo.svg)`, backgroundColor: `#eee`,
          backgroundSize: `auto`
        }}></div>
      )}
      <div className="card__body">
      <h3 className={`card__title ${titleModifier} mb-0`} dangerouslySetInnerHTML={{__html: title}}></h3>
      <Stars count={rating} />
      {btn &&
          <span className="btn btn--secondary mt-2">{btn}<i className="icon">chevron_right</i></span>
      }
      </div>
      {/* <i className="icon icon-link">open_in_new</i> */}
    </Link>
  )
}

export default Card