import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactGA from "react-ga"
import Home from "./page/home"
import City from "./page/city"
import Country from "./page/country"
import Place from "./page/place"

import DATA_CITY from './data/city'
import LIST_CITY from './data/list-city'

const MENU_CITY = ['tokyo']


function App() {

  ReactGA.initialize('UA-6498833-27')

  return (
    <Router>
      <header className="header">
        <Link className="header__logo" to="/"><img alt="logo best-ramen" src="/img/logo.svg"/><span className="ft-up ft-800">Best ramen</span></Link>
        <ul className="header__links">
          <li>
            <Link to="/">Home</Link>
          </li>
          {MENU_CITY.map((name, index) => (
              <li key={`nav_${index}`}>
                <Link to={`/city/${name}`}>{DATA_CITY[name].label}</Link>
              </li>
          ))}
          <li>
            <Link to="/japan">Japan</Link>
          </li>
          <li>
            <a href="https://www.instagram.com/best.ramen/" target="_blank" rel="noopener noreferrer">Instagram</a>
          </li>
        </ul>
      </header>

      <Route path="/city/:city" component={City} />
      <Route path="/place/:slug" component={Place} />
      <Route exact strict path="/:country(japan|france)" component={Country} />
      <Route exact path="/about" component={About} />
      <Route exact path="/" component={Home} />

      <footer className="footer section mt-6 mt-md-8">
        <div className="container">
          <Link className="footer__logo" to="/"><img alt="logo best-ramen" src="/img/logo.svg"/><span className="ft-up ft-800">Best ramen</span></Link>
          <div className="row footer__links">
            <ul className="col col-md-3">
              <li className="footer__category ft-primary">Best of Tokyo</li>
              <li><Link to="/city/tokyo">Best ramen in Tokyo</Link></li>
              <li><Link to="/city/tokyo/tonkotsu">Best tonkotsu ramen in Tokyo</Link></li>
              <li><Link to="/city/tokyo/shoyu">Best shoyu ramen in Tokyo</Link></li>
              <li><Link to="/city/tokyo/miso">Best miso ramen in Tokyo</Link></li>
              <li><Link to="/city/tokyo/shio">Best shio ramen in Tokyo</Link></li>
              <li><Link to="/city/tokyo/tsukemen">Best tsukemen in Tokyo</Link></li>
              <li><Link to="/city/tokyo/abura">Best abura soba in Tokyo</Link></li>
            </ul>
            
            <ul className="col col-md-3">
              <li className="footer__category ft-primary">Best of ramen</li>
              {LIST_CITY.map((name, index) => (
                <li key={`ft_r_${index}`}><Link to={`/city/${name}`}>Best ramen in {DATA_CITY[name].label}</Link></li>
              ))}
            </ul>
            <ul className="col col-md-3">
              <li className="footer__category ft-primary">Best of Mensho</li>
              <li><Link to="/place/mensho-tokyo">Lamb tsukemen</Link></li>
              <li><Link to="/place/mensho-tokyo-mazesoba">Lamb mazesoba</Link></li>
              <li><Link to="/place/mensho-san-francisco">Wagyu Shoyu Ramen</Link></li>
              <li><Link to="/place/mensho">Shoyu tsukemen</Link></li>
              <li><Link to="/place/mensho-shoyu-ramen">Shoyu ramen</Link></li>
            </ul>
            <ul className="col col-md-3">
              <li className="footer__category ft-primary">My favourites</li>
              <li><Link to="/place/tomita-matsudo">Tomita tsukemen</Link></li>
              <li><Link to="/place/mensho-tokyo">Mensho Lamb tsukemen</Link></li>
              <li><Link to="/place/mazemen-mahoroba">Mazemen Mahoroba</Link></li>
              <li><Link to="/place/ramen-shingen">Shingen Miso Ramen </Link></li>
              <li><Link to="/place/yamatame-shokudo-ramen">Yamatame Shokudo Shoyu Ramen</Link></li>
              <li><Link to="/place/genki-ippai">Genki Ippai Tonkotsu ramen</Link></li>
            </ul>
          </div>
          <div className="footer__corporate">
            <p className="ft-tertiary mb-0">
              © Copyright 2019 best-ramen.com All rights reserved
            </p>
          </div>
        </div>
      </footer>
    </Router>
  );
}



function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}


export default App;
