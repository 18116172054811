/* eslint-disable default-case */
import React, { Component } from 'react'
import { Route, NavLink } from "react-router-dom";
import ReactGA from "react-ga"

import Banner from "../components/banner"
import Card from "../components/card"
import generateMeta from "../utils/meta"


import DATA_CITY from '../data/city'

class City extends Component {
    state = {
        city: this.props.match.params.city,
        places: [],
        categories: [],
        cache: {}
    }

    componentDidMount() {
        this.fetchPlaces(this.state.city)

        ReactGA.pageview(window.location.pathname);
    }

    componentWillReceiveProps({match}) {
        ReactGA.pageview(window.location.pathname);

        if (match.params.city !== this.props.match.params.city) {
            this.setState({ city: match.params.city });
            this.fetchPlaces(match.params.city)
        }
    }

    fetchPlaces(city) {
        if(this.state.cache[city]) {
            this.setState({places: this.state.cache[city]})
        } else {
            fetch(`/api/place?view=ramen&filterByFormula={city}="${city}"`)
            .then(resp => resp.json())
            .then(data => {
                if(data.records) {
                    let newCache = {...this.state.cache}
                    newCache[city] = data.records;
                    this.setState({
                        places: data.records,
                        cache: newCache
                    })
                    this.fetchCategory()
                }
            })
            .catch(err => {
              // Error :(
            })
        }
    }

    fetchCategory() {
        let tags = []
        this.state.places.forEach(p => {
            tags = [...tags, ...p.fields.tag]
        })
        const uniqueTags = new Set(tags);
        this.setState({ categories: [...uniqueTags] });
    }

    render() {
        this.setMeta()

        window.scroll(0, 0)
        const { url } = this.props.match
        const {city, categories} = this.state
        const { cover, cover_webp, label } = DATA_CITY[city]

        return (
            <React.Fragment>
                <Banner className="banner--navbar banner--city" img={cover} webp={cover_webp} title={`Best ramen in ${label}`} />

                <div className="container mb-4">
                    <div className="box">
                        <nav className="navbar navbar--search">
                            <ul className="navbar__links">
                                <li><NavLink exact to={`${url}`} activeClassName="is-active">All</NavLink></li>
                                {categories.map((c, i) => {
                                    return(
                                        <li key={`c_${i}`}><NavLink to={`${url}/${c}`} activeClassName="is-active">{c}</NavLink></li>
                                    )
                                })} 
                                {/* <li><NavLink to={`${url}/tsukemen`} activeClassName="is-active">Tsukemen</NavLink></li>
                                <li><NavLink to={`${url}/abura`} activeClassName="is-active">Abura Soba</NavLink></li>
                                <li><NavLink to={`${url}/tonkotsu`} activeClassName="is-active">Tonkotsu</NavLink></li>
                                <li><NavLink to={`${url}/shoyu`} activeClassName="is-active">Shoyu</NavLink></li>
                                <li><NavLink to={`${url}/miso`} activeClassName="is-active">Miso</NavLink></li>
                                <li><NavLink to={`${url}/shio`} activeClassName="is-active">shio</NavLink></li> */}
                            </ul>
                            {/* <form className="js-search-dev" data-baseurl="">
                                <div className="input input--search input--transparent">
                                <label>Search a place</label>
                                <input type="search" name="query" placeholder="Search a place" autoComplete="off"/>
                                <div className="input__completion"></div>
                                </div>
                            </form> */}
                        </nav>
                        <div className="box__padding">
                            <Route exact path="/city/:city" component={this.ListPlace} />
                            <Route path="/city/:city/:type" component={this.ListPlace} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
          )
    }

    ListPlace = ({ match }) => {
        this.setMeta(match.params.type)

        const filteredPlaces = this.getFilteredPlaces(match.params.type)        
        const totalPlaces = filteredPlaces.length

        return(
            <div>
                {totalPlaces === 0 &&
                    <p>0 result for this city</p>
                }
                <div className="mosaic">
                    {filteredPlaces.map((place, index) => {
                        return(
                            <Card 
                                title={place.fields.name}
                                link={`/place/${place.fields.slug}`}
                                img={place.fields.photo_jpg} 
                                webp={place.fields.photo_webp}
                                titleModifier={`h4`}
                                modifier={``}
                                rating={place.fields.reviewRating}
                                key={`place_${index}`} />
                        )
                    })}                        
                </div>
            </div>
        )
    }

    getFilteredPlaces(type = '') {
        switch(type) {
            case '': return this.state.places
            default: return this.state.places.filter(p => {
                return p.fields.tag && p.fields.tag.indexOf(type) >= 0
            }) 
        }
    }

    setMeta(type) {
        const {city} = this.state
        const { label } = DATA_CITY[city]

        let typeLabel = type || "ramen"
        const slugType = type ? `/${type}` : ""

        switch (typeLabel) {
            case 'shio' :
            case 'shoyu' : 
            case 'wagyu' : 
            case 'paitan' : 
            case 'yuzu' : 
            case 'tonkotsu' : 
                typeLabel += " ramen";
                break;
            case 'abura' : 
                typeLabel = "abura soba";
                break;
        }
  
        generateMeta([
            {
              type: 'title',
              content: `Review of the best ${typeLabel} in ${label}`,
            },
            {
              type: 'description',
              content: `Find my review of the best ${typeLabel} in ${label} (Japan), photos, rating, address and all the useful information`,
            },
            {
              property: 'og:url',
              content: `https://best-ramen.com/city/${city+slugType}`,
            },
            {
              property: 'og:image',
              content: `https://best-ramen.com/img/home.jpg`,
            },
            {
              property: 'twitter:url',
              content: `https://best-ramen.com/city/${city+slugType}`,
            },
            {
              property: 'twitter:image',
              content: `https://best-ramen.com/img/home.jpg`,
            },
          ]
        )
    }
    
}


export default City;
