import React, { Component } from 'react'
import ReactGA from "react-ga"

import Card from "../components/card"
import Banner from "../components/banner"
import generateMeta from "../utils/meta"

import DATA_CITY from '../data/city'
import LIST_CITY from '../data/list-city'


class Country extends Component {
    state = {
        country: this.props.match.params.country,
        cities: {
            japan: LIST_CITY,
            france: ['paris', 'nice', 'monaco']
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    componentWillReceiveProps({match}) {
        if (match.params.country !== this.props.match.params.country) {
            this.setState({ country: match.params.country });
            ReactGA.pageview(window.location.pathname);
        }
    }

    render() {
        this.setMeta()
        window.scroll(0, 0)
        let {country, cities} = this.state
        return (
            <React.Fragment>
                <Banner className="mb-4" img={`/img/country/banner/${country}.jpg`} title={`Discover the best places in ${country}`} />
                <div className="container">
                    <div className="list-card">
                        {cities[country].map((name, key) => (
                            <Card 
                                title={DATA_CITY[name].label}
                                link={`/city/${name}`}
                                img={DATA_CITY[name].thumbnail}
                                webp={DATA_CITY[name].thumbnail_webp}
                                modifier={`card--hub mb-2 mb-md-3`}
                                key={`cities_${key}`} />
                        ))}
                    </div>
                </div>
            </React.Fragment>
          )
    }

    setMeta() {

        generateMeta([
            {
              type: 'title',
              content: `Cities in Japan with the best ramen restaurant`,
            },
            {
              type: 'description',
              content: `Ramen or Tsukemen? In Tokyo, Kyoto, Osaka or in the far Fukuoka, Find the best ramen city!`,
            },
            {
              property: 'og:url',
              content: `https://best-ramen.com/japan`,
            },
            {
              property: 'og:image',
              content: `https://best-ramen.com/img/home.jpg`,
            },
            {
              property: 'twitter:url',
              content: `https://best-ramen.com/japan`,
            },
            {
              property: 'twitter:image',
              content: `https://best-ramen.com/img/home.jpg`,
            },
          ]
        )
    }
}


export default Country;
