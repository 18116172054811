import React, { Component } from 'react'
import ReactGA from "react-ga"
import Card from "../components/card"
import Banner from "../components/banner"

import checkWebp from "../utils/checkWebp"
import generateMeta from "../utils/meta"

import DATA_CITY from '../data/city'

class Home extends Component {
    state = {
        discover: ['kyoto', 'nara', 'sapporo', 'osaka', 'hiroshima'],
        places: [],
        hasWebp: checkWebp()
    }

    componentDidMount() {
        this.fetchPlaces()
        ReactGA.pageview(window.location.pathname);
    }


    fetchPlaces(city) {
        fetch(`/api/place?view=ramen&maxRecords=5`)
        .then(resp => resp.json())
        .then(data => {
            if(data.records) {
                this.setState({
                    places: data.records
                })
            }
        })
        .catch(err => {
            // Error :(
        })
    }

    render() {
        this.setMeta()
        window.scroll(0, 0)

        let {discover} = this.state

        const bannerTitle = "Discover the best ramen in Japan"
        const bannerDesc = "My personnal review of Ramen, Tsukemen and other noodles dishes I had while living in Japan."

        const lastPlace = this.state.places[0]
        const lastReviews = this.state.places.slice(1)

        return (
            <React.Fragment>
                <Banner className="banner--home mb-4 mb-md-6 mb-lg-8" img={`/img/home.jpg`} webp={`/img/home.webp`} title={bannerTitle} desc={bannerDesc} />
                <div className="container">
                    <h2>Latest news</h2>
                    <p className="ft-black-secondary mb-3 mb-md-4">Find my last review and personnal selection.</p>
                    <div className="row row--flex">
                        <div className="col col-md-6 mb-2 mb-md-0">
                        {lastPlace &&
                            <Card 
                                title={`MENSHO`}
                                link={`/place/mensho`}
                                img={`/img/ramen/1024/mensho.jpg`}
                                webp={`/img/ramen/1024/mensho.webp`}
                                modifier={`card--hub card--square`} 
                                rating={`5`} />
                        }
                        </div>
                        <div className="col col-md-6 home-mosaic">
                            <Card 
                                title={`Sapporo Selection`}
                                link={`/city/sapporo`}
                                img={DATA_CITY["sapporo"].thumbnail}
                                webp={DATA_CITY["sapporo"].thumbnail_webp}
                                modifier={`card--hub`} 
                                 />
                            <div className="row mt-2 mt-md-3 mt-lg-4">
                                <div className="col col-xs-2 col-md-6">
                                    <Card 
                                        title={`Tokyo<br/>Tonkotsu`}
                                        link={`/city/tokyo/tonkotsu`}
                                        img={DATA_CITY["tokyo"].thumbnail}
                                        webp={DATA_CITY["tokyo"].thumbnail_webp}
                                        modifier={`card--hub card--center`}  />
                                </div>
                                <div className="col col-xs-2 col-md-6">
                                    <Card 
                                        title={`Tokyo<br/>Tsukemen`}
                                        link={`/city/tokyo/tsukemen`}
                                        img={DATA_CITY["tokyo"].thumbnail}
                                        webp={DATA_CITY["tokyo"].thumbnail_webp}
                                        modifier={`card--hub card--center`}  />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="mt-3 mb-3 mt-md-6 mb-md-6 mt-lg-8 mb-lg-7"/>

                    <h2>Selection by city</h2>
                    <p className="ft-black-secondary mb-3 mb-md-4">Ramen, tsukemen, mazesoba, abura soba...</p>
                    <Card 
                        title={`Best of ${DATA_CITY['tokyo'].label}`}
                        link={`/city/tokyo`}
                        img={DATA_CITY['tokyo'].cover}
                        webp={DATA_CITY['tokyo'].cover_webp}
                        btn={`Discover the best restaurants`}
                        titleModifier={`h2`}
                        modifier={`card--hub card--portrait card--center`} />
                    <div className="list-card list-card--scroll list-card--5 mt-2 mt-md-3 mt-lg-4">
                        {discover.map((name, key) => (
                            <Card 
                                title={DATA_CITY[name].label}
                                link={`/city/${name}`}
                                img={DATA_CITY[name].thumbnail}
                                webp={DATA_CITY[name].thumbnail_webp}
                                modifier={`card--hub card--portrait`} 
                                key={`home_city_${key}`} />
                        ))}
                    </div>
                    
                    <hr className="mt-3 mb-3 mt-md-6 mb-md-6 mt-lg-8 mb-lg-7"/>

                    <h2 className="mb-2 mb-md-3">Last reviews</h2>

                    <div className="list-card list-card--4 mb-1">
                        {lastReviews.map((place, key) => (
                            <Card 
                                title={place.fields.name}
                                link={`/place/${place.fields.slug}`}
                                img={place.fields.photo_jpg} 
                                webp={place.fields.photo_webp}
                                modifier={`mb-2 mb-md-3`}
                                titleModifier={`h4`}
                                rating={place.fields.reviewRating}
                                key={`home_review_${key}`} />
                        ))}
                    </div>
                </div>
            </React.Fragment>
          )
    }

    setMeta() {
        generateMeta([
            {
              type: 'title',
              content: 'The Best Ramen place in Japan',
            },
            {
              type: 'description',
              content: 'My personnal review of Ramen, Tsukemen and other noodles dishes I had while living in Japan.',
            },
            {
              property: 'og:url',
              content: 'https://best-ramen.com',
            },
            {
              property: 'og:image',
              content: 'https://best-ramen.com/img/home.jpg',
            },
            {
              property: 'twitter:url',
              content: 'https://best-ramen.com',
            },
            {
              property: 'twitter:image',
              content: 'https://best-ramen.com/img/home.jpg',
            },
          ]
        )
    }
}


export default Home;
