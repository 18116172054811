import React, { Component } from 'react'
import ReactGA from "react-ga"

import Banner from "../components/banner"
import Stars from "../components/stars"

import generateMeta from "../utils/meta"
import stripHtml from "../utils/stripe"


class Place extends Component {
    state = {
      slug: this.props.match.params.slug,
      place: {},
      cache: {}
    }

    componentDidMount() {
      this.fetchPlace(this.state.slug)
      ReactGA.pageview(window.location.pathname)
    }

    componentWillReceiveProps({match}) {
      if (match.params.slug !== this.props.match.params.slug) {
        ReactGA.pageview(window.location.pathname);
        this.setState({ slug: match.params.slug });
        this.fetchPlace(match.params.slug)
      }
    }

    fetchPlace(slug) {
      if(this.state.cache[slug]) {
          this.setState({place: this.state.cache[slug]})
      } else {
          fetch(`/api/place?maxRecords=1&filterByFormula={slug}="${slug}"`)
          .then(resp => resp.json())
          .then(data => {
              if(data.records) {
                  let newCache = {...this.state.cache}
                  newCache[slug] = data.records[0].fields
                  this.setState({
                      place: data.records[0].fields,
                      cache: newCache
                  })
              }
          })
          .catch(err => {
            // Error :(
              console.log(err)
          })
      }
    }

    render() {
      this.setMeta()

      window.scroll(0, 0)
      let {name, address, map_url, reviewRating=[], reviewComment, reviewDate, tag, photo_jpg, photo_webp, city} = this.state.place

      return (
        <React.Fragment>
          <Banner title={name} className="banner--small mb-4" tag={tag} img={photo_jpg} webp={photo_webp} />

          <div className="container mb-4">
            <div className="row">

              <div className="col col-md-8 col--center">
                {photo_jpg &&
                  <picture>
                    <source srcSet={photo_webp} type="image/webp" />
                    <source srcSet={photo_jpg} type="image/jpeg" /> 
                    <img src={photo_jpg} alt={name} className="img-review" />
                  </picture>
                }
                <div className="box box--padding">
                  {reviewRating &&
                    <>
                    <h2>My review</h2>
                    <p><Stars count={reviewRating} /> {reviewDate}</p>
                    <div dangerouslySetInnerHTML={{__html: reviewComment}}></div>
                    </>
                  }

                  {/* <span className="tag" >{category}</span> */}


                  <h2 className="mt-3">Address</h2>
                  <p>{address}</p>
                  <a className="btn btn--secondary" href={map_url} target="_blank" rel="noopener noreferrer">See on Google Map</a>
                </div>
              </div>


            </div>
          </div>
          <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Restaurant",
              "image": `https://best-ramen.com${photo_jpg}`,
              "name": name,
              "servesCuisine": "Ramen",
              "address" :{
                "@type": "PostalAddress",
                "streetAddress": address,
                "addressLocality": city,
                "addressCountry": "JP"
              },
              "priceRange": "$"
            },
            "reviewRating": {
              "@type": "Rating",
              "bestRating": "5",
              "worstRating": "1",
              "ratingValue": reviewRating[0]
            },
            "datePublished": reviewDate,
            "reviewBody": reviewComment,
            "author": {
              "@type": "Organization",
              "name": "best-ramen.com",
              "url": "https://best-ramen.com"
            },
            "publisher": {
              "@type": "Organization",
              "name": "best-ramen.com",
              "url": "https://best-ramen.com"
            }
          })}
          </script>
        </React.Fragment>
      )
    }

    setMeta() {
      let {name, reviewComment, photo_jpg, city } = this.state.place

      if(!name) { 
        return; 
      }

      generateMeta([
          {
            type: 'title',
            content: `Review of ${name} in ${city.charAt(0).toUpperCase()+city.slice(1)}`,
          },
          {
            type: 'description',
            content: stripHtml(reviewComment[0]).substring(0, 165),
          },
          {
            property: 'og:url',
            content: `https://best-ramen.com/place/${this.state.slug}`,
          },
          {
            property: 'og:image',
            content: `https://best-ramen.com${photo_jpg}`,
          },
          {
            property: 'twitter:url',
            content: `https://best-ramen.com/place/${this.state.slug}`,
          },
          {
            property: 'twitter:image',
            content: `https://best-ramen.com${photo_jpg}`,
          },
        ]
      )
  }
}


export default Place;
