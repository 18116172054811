import React from 'react'

const Stars = props => {
  let {count} = props
  let html = "";

  for (let index = 0; index < count; index++) {
    html += "★";
  }

  return (
    <span className="ft-secondary">{html}</span>
  )
}

export default Stars