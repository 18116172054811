import React from 'react'
import checkWebp from "../utils/checkWebp"


const Banner = props => {
  let {title, img, webp=img, className="", tag, desc} = props

  const hasWebp = checkWebp();
  const bg = hasWebp ? webp : img;

  console.log('tag', tag)

  return (
    <section className={`banner ${className}`}>
      <div className="container">
        <h1 className="banner__title mb-0">{title}</h1>
        {tag && tag.map((t, i) => {
            return(
              <span key={`t_${i}`} className="label mr-2">{t}</span>
            )
        })} 
        {desc &&
          <p className="ft-white-secondary ft-primary">{desc}</p>
        }
      </div>
      <div className="banner__thumbnail lazy img-dark" style={{backgroundImage: `url(${bg})`}}></div>
    </section>
  )
}

export default Banner