function generateMeta(metaArray) {
  for (let i = 0; i < metaArray.length; i++) {
    let metaItem = metaArray[i]
    let type = metaItem.type || 'meta'

    if (metaItem.type === 'title') {
      let title = document.querySelector('title')
      title.innerText = metaItem.content
      let metaTitle = document.querySelectorAll(`meta[property='og:title'], meta[property='twitter:title']`)
      for (const el of metaTitle) {
        el.content = metaItem.content
      }
    }
    if (metaItem.type === 'description') {
      let desc = document.querySelectorAll(`meta[name='description'], meta[property='og:description'], meta[property='twitter:description']`)
      for (const el of desc) {
        el.content = metaItem.content
      }
    }

    if (metaItem.property) {
      let meta = document.querySelector(`${type}[property='${metaItem.property}']`)
      meta.content = metaItem.content
    }
      
  }
}

// function addMeta({property, content}) {
//   let meta = document.createElement('meta')
//   meta.setAttribute('property', property)
//   meta.content = content
//   document.querySelector('head').appendChild(meta)
// }

export default generateMeta